export default
  `This Behavior is meant to simulate a gate change in an airport.

1. Fill in the Location "New_Gate"
  - Located at X: 10, Y: 35
  - Sides of length X: 20, Y: 10
  - No rotation

2. Fill in the Event "Gate_Change"
  - Occurs a set time after the start of the simulation, Any time will be accepted
  - Lasts for a set time, Any time will be accepted

3. Fill in the Action
  - In response to the "Gate_Change" event starting
  - Change the "Passengers" goal to the "New_Gate" location


Types: Passenger
Locations: New_Gate
Events: Gate_Change

References:
  (§S). Selectors
  (§L). Locations
  (§E). Events
  (§A). Actions
  (§V). Values
`;